var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-prompt',{attrs:{"vs-title":_vm.lang.botMaker.addBot.priorities[
        _vm.editingPriority ? 'editPriority' : 'addPriority'
      ].prompt.title[_vm.languageSelected],"vs-active":_vm.isPromptActive,"vs-accept-text":_vm.lang.botMaker.addBot.priorities.prompt.accept[_vm.languageSelected],"vs-cancel-text":_vm.lang.botMaker.addBot.priorities.prompt.cancel[_vm.languageSelected],"vs-button-cancel":"flat","vs-is-valid":_vm.promptPriorityTags.length > 0 || _vm.promptPriorityIntents.length > 0},on:{"update:vsActive":function($event){_vm.isPromptActive=$event},"vs-accept":_vm.saveTaggedPriority}},[_c('vs-row',[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-input',{attrs:{"type":"number","required":"","label":_vm.lang.botMaker.addBot.priorities.prompt.priorityValueLabel[
              _vm.languageSelected
            ],"min":1,"max":1000},on:{"keypress":function($event){_vm.isValid($event)}},model:{value:(_vm.promptPriorityValue),callback:function ($$v) {_vm.promptPriorityValue=$$v},expression:"promptPriorityValue"}})],1),_vm._v(" "),_c('vs-col',{staticClass:"mt-4",attrs:{"vs-w":"12"}},[_c('vs-input',{staticClass:"mb-2 w-full",attrs:{"label":_vm.lang.botMaker.addBot.priorities.prompt.intentsLabel[
              _vm.languageSelected
            ],"placeholder":_vm.lang.botMaker.addBot.priorities.prompt.intentsPlaceholder[
              _vm.languageSelected
            ]},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.addToArray('promptPriorityIntents', 'promptPriorityIntentValue')}},model:{value:(_vm.promptPriorityIntentValue),callback:function ($$v) {_vm.promptPriorityIntentValue=$$v},expression:"promptPriorityIntentValue"}}),_vm._v(" "),_vm._l((_vm.promptPriorityIntents),function(intent,index){return _c('vs-chip',{key:index,staticClass:"mr-2 mt-3",attrs:{"color":"primary","closable":""},on:{"click":function($event){_vm.promptPriorityIntents.splice(
              _vm.promptPriorityIntents.indexOf(intent),
              1
            )}}},[_c('vs-avatar',{staticClass:"text-lg",attrs:{"color":"primary","text":"#️"}}),_vm._v(_vm._s(intent)+"\n        ")],1)})],2),_vm._v(" "),_c('vs-col',{staticClass:"mt-2",attrs:{"vs-w":"12"}},[_c('vs-input',{staticClass:"mb-2 w-full",attrs:{"label":_vm.lang.botMaker.addBot.priorities.prompt.tagsLabel[_vm.languageSelected],"placeholder":_vm.lang.botMaker.addBot.priorities.prompt.tagsPlaceholder[
              _vm.languageSelected
            ]},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.addToArray('promptPriorityTags', 'promptPriorityTagValue')}},model:{value:(_vm.promptPriorityTagValue),callback:function ($$v) {_vm.promptPriorityTagValue=$$v},expression:"promptPriorityTagValue"}}),_vm._v(" "),_vm._l((_vm.promptPriorityTags),function(tag,index){return _c('vs-chip',{key:index,staticClass:"mr-2 mt-3",attrs:{"color":"primary","closable":""},on:{"click":function($event){_vm.promptPriorityTags.splice(_vm.promptPriorityTags.indexOf(tag), 1)}}},[_c('vs-avatar',{staticClass:"text-lg",attrs:{"color":"primary","text":"🏷"}}),_vm._v(_vm._s(tag)+"\n        ")],1)})],2)],1)],1),_vm._v(" "),_c('add-bot-section',{staticClass:"add-bot-section",attrs:{"title":_vm.lang.botMaker.addBot.priorities.title[_vm.languageSelected]}},[_c('template',{slot:"actions"},[(_vm.isEditing)?_c('vs-button',{on:{"click":_vm.toggleAddPriorityNew}},[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.priorities.addPriority.button[_vm.languageSelected])+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('template',{slot:"whole-w"},[_c('h3',{staticClass:"description"},[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.addBot.priorities.description[_vm.languageSelected])+"\n        "),(!_vm.bot || !_vm.bot.service || !_vm.bot.service.id)?_c('KonaAlert',{attrs:{"icon":"AlertCircleIcon","bg-color":"rgba(var(--vs-warning), 0.15)","color":"rgba(var(--vs-warning), 1)","size":"small"}},[_vm._v("\n          "+_vm._s(_vm.lang.botMaker.addBot.priorities.disclaimer[_vm.languageSelected])+"\n        ")]):_vm._e()],1),_vm._v(" "),(_vm.isEditing)?_c('div',[_c('vs-table',{attrs:{"max-items":"10","pagination":"","data":_vm.taggedPriorities,"search":true,"hoverFlat":true,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.priority}},[_c('vs-chip',{attrs:{"color":tr.priority === 1 ? 'danger' : 'warning'}},[_c('div',{},[_vm._v(_vm._s(tr.priority))])])],1),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.tags}},_vm._l((tr.tags),function(listItem,index){return _c('vs-chip',{key:index,attrs:{"color":"primary"}},[_c('vs-avatar',{staticClass:"text-lg",attrs:{"color":"primary","text":"🏷"}}),_vm._v("\n                  "+_vm._s(listItem))],1)}),1),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.intents}},_vm._l((tr.intents),function(listItem,index){return _c('vs-chip',{key:index,attrs:{"color":"primary"}},[_c('vs-avatar',{staticClass:"text-lg",attrs:{"color":"primary","text":"#️"}}),_vm._v("\n                  "+_vm._s(listItem)+"\n                ")],1)}),1),_vm._v(" "),_c('vs-td',{attrs:{"data":tr._updatedAt}},[_vm._v(_vm._s(_vm._f("date")(new Date(tr._updatedAt || tr._createdAt))))]),_vm._v(" "),_c('vs-td',[_c('div',{staticClass:"action-buttons"},[_c('vs-button',{staticClass:"with-space",attrs:{"type":"flat","icon":"create"},on:{"click":function($event){_vm.toggleAddPriorityEdit(tr, indextr)}}}),_vm._v(" "),_c('vs-button',{attrs:{"type":"flat","icon-pack":"feather","icon":"icon-trash"},on:{"click":function($event){_vm.deleteTaggedPriority(tr, indextr)}}})],1)])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sortKey":"priority"}},[_vm._v("\n              "+_vm._s(_vm.lang.botMaker.addBot.priorities.tableHeaders.priority[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.botMaker.addBot.priorities.tableHeaders.tags[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.botMaker.addBot.priorities.tableHeaders.intents[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('vs-th',{attrs:{"sortKey":"_updatedAt"}},[_vm._v("\n              "+_vm._s(_vm.lang.botMaker.addBot.priorities.tableHeaders.updated[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('vs-th')],1)],2)],1):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }