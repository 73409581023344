<template>
  <div>
    <vs-prompt
      :vs-title="
        lang.botMaker.addBot.priorities[
          editingPriority ? 'editPriority' : 'addPriority'
        ].prompt.title[languageSelected]
      "
      :vs-active.sync="isPromptActive"
      :vs-accept-text="
        lang.botMaker.addBot.priorities.prompt.accept[languageSelected]
      "
      :vs-cancel-text="
        lang.botMaker.addBot.priorities.prompt.cancel[languageSelected]
      "
      vs-button-cancel="flat"
      @vs-accept="saveTaggedPriority"
      :vs-is-valid="
        promptPriorityTags.length > 0 || promptPriorityIntents.length > 0
      "
    >
      <vs-row>
        <vs-col vs-w="12">
          <vs-input
            type="number"
            required
            :label="
              lang.botMaker.addBot.priorities.prompt.priorityValueLabel[
                languageSelected
              ]
            "
            :min="1"
            :max="1000"
            v-model="promptPriorityValue"
            @keypress="isValid($event)"
          />
        </vs-col>

        <vs-col vs-w="12" class="mt-4">
          <vs-input
            :label="
              lang.botMaker.addBot.priorities.prompt.intentsLabel[
                languageSelected
              ]
            "
            v-on:keyup.enter="
              addToArray('promptPriorityIntents', 'promptPriorityIntentValue')
            "
            :placeholder="
              lang.botMaker.addBot.priorities.prompt.intentsPlaceholder[
                languageSelected
              ]
            "
            class="mb-2 w-full"
            v-model="promptPriorityIntentValue"
          />
          <vs-chip
            @click="
              promptPriorityIntents.splice(
                promptPriorityIntents.indexOf(intent),
                1
              )
            "
            class="mr-2 mt-3"
            color="primary"
            v-for="(intent, index) in promptPriorityIntents"
            :key="index"
            closable
          >
            <vs-avatar color="primary" text="#️" class="text-lg" />{{ intent }}
          </vs-chip>
        </vs-col>

        <vs-col vs-w="12" class="mt-2">
          <vs-input
            :label="
              lang.botMaker.addBot.priorities.prompt.tagsLabel[languageSelected]
            "
            v-on:keyup.enter="
              addToArray('promptPriorityTags', 'promptPriorityTagValue')
            "
            :placeholder="
              lang.botMaker.addBot.priorities.prompt.tagsPlaceholder[
                languageSelected
              ]
            "
            class="mb-2 w-full"
            v-model="promptPriorityTagValue"
          />
          <vs-chip
            @click="
              promptPriorityTags.splice(promptPriorityTags.indexOf(tag), 1)
            "
            class="mr-2 mt-3"
            color="primary"
            v-for="(tag, index) in promptPriorityTags"
            :key="index"
            closable
          >
            <vs-avatar color="primary" text="🏷" class="text-lg" />{{ tag }}
          </vs-chip>
        </vs-col>
      </vs-row>
    </vs-prompt>

    <add-bot-section
      :title="lang.botMaker.addBot.priorities.title[languageSelected]"
      class="add-bot-section"
    >
      <template slot="actions">
        <vs-button v-if="isEditing" @click="toggleAddPriorityNew">
          {{
            lang.botMaker.addBot.priorities.addPriority.button[languageSelected]
          }}
        </vs-button>
      </template>
      <template slot="whole-w">
        <h3 class="description">
          {{ lang.botMaker.addBot.priorities.description[languageSelected] }}
          <KonaAlert
            v-if="!bot || !bot.service || !bot.service.id"
            icon="AlertCircleIcon"
            bg-color="rgba(var(--vs-warning), 0.15)"
            color="rgba(var(--vs-warning), 1)"
            size="small"
          >
            {{ lang.botMaker.addBot.priorities.disclaimer[languageSelected] }}
          </KonaAlert>
        </h3>

        <div v-if="isEditing">
          <vs-table
            max-items="10"
            pagination
            :data="taggedPriorities"
            :search="true"
            :hoverFlat="true"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sortKey="priority">
                {{
                  lang.botMaker.addBot.priorities.tableHeaders.priority[
                    languageSelected
                  ]
                }}
              </vs-th>
              <vs-th>
                {{
                  lang.botMaker.addBot.priorities.tableHeaders.tags[
                    languageSelected
                  ]
                }}
              </vs-th>
              <vs-th>
                {{
                  lang.botMaker.addBot.priorities.tableHeaders.intents[
                    languageSelected
                  ]
                }}
              </vs-th>
              <vs-th sortKey="_updatedAt">
                {{
                  lang.botMaker.addBot.priorities.tableHeaders.updated[
                    languageSelected
                  ]
                }}
              </vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.priority">
                  <vs-chip :color="tr.priority === 1 ? 'danger' : 'warning'">
                    <div class="">{{ tr.priority }}</div>
                  </vs-chip>
                </vs-td>

                <vs-td :data="tr.tags">
                  <vs-chip
                    color="primary"
                    v-for="(listItem, index) in tr.tags"
                    :key="index"
                  >
                    <vs-avatar color="primary" text="🏷" class="text-lg" />
                    {{ listItem }}</vs-chip
                  >
                </vs-td>

                <vs-td :data="tr.intents">
                  <vs-chip
                    color="primary"
                    v-for="(listItem, index) in tr.intents"
                    :key="index"
                  >
                    <vs-avatar color="primary" text="#️" class="text-lg" />
                    {{ listItem }}
                  </vs-chip>
                </vs-td>

                <vs-td :data="tr._updatedAt">{{
                  new Date(tr._updatedAt || tr._createdAt) | date
                }}</vs-td>

                <vs-td>
                  <div class="action-buttons">
                    <vs-button
                      class="with-space"
                      type="flat"
                      @click="toggleAddPriorityEdit(tr, indextr)"
                      icon="create"
                    ></vs-button>
                    <vs-button
                      type="flat"
                      @click="deleteTaggedPriority(tr, indextr)"
                      icon-pack="feather"
                      icon="icon-trash"
                    ></vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </template>
    </add-bot-section>
  </div>
</template>

<script>
import taggedPrioritiesService from '../../../../services/taggedPriority.service'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PrioritiesSettings',
  components: {
    KonaAlert: () => import('@/components/KonaAlert.vue'),
    AddBotSection: () => import('./Components/AddBotSection.vue')
  },
  props: {
    isEditing: Boolean
  },
  data() {
    return {
      taggedPriorities: [],
      isPromptActive: false,
      promptPriorityValue: 100,
      promptPriorityIntentValue: '',
      promptPriorityIntents: [],
      promptPriorityTagValue: '',
      promptPriorityTags: [],

      editingPriority: false,
      promptPriorityId: null,
      promptPriorityIndex: -1
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'newBotLanguage']),
    ...mapGetters('bots', ['bot'])
  },
  methods: {
    deleteTaggedPriority(tr, indextr) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.botMaker.addBot.priorities.deleteConfirmation.title[
          this.languageSelected
        ],
        text: this.lang.botMaker.addBot.priorities.deleteConfirmation.content[
          this.languageSelected
        ],
        acceptText: this.lang.botMaker.accept[this.languageSelected],
        cancelText: this.lang.botMaker.cancel[this.languageSelected],
        accept: () => {
          taggedPrioritiesService
            .deleteTaggedPriority(tr._id)
            .then(() => {
              this.taggedPriorities.splice(indextr, 1)
              this.$vs.notify({
                title: this.lang.botMaker.addBot.priorities.genericSuccess
                  .title[this.languageSelected],
                text: this.lang.botMaker.addBot.priorities.genericSuccess.text[
                  this.languageSelected
                ],
                color: 'success'
              })
            })
            .catch(error => {
              console.error(error)
              this.$vs.notify({
                title: this.lang.botMaker.addBot.priorities.genericError.title[
                  this.languageSelected
                ],
                text: this.lang.botMaker.addBot.priorities.genericError.text[
                  this.languageSelected
                ],
                color: 'danger'
              })
            })
        }
      })
    },
    saveTaggedPriority() {
      if (
        this.promptPriorityValue < 1 ||
        this.promptPriorityValue > 1000 ||
        !/^\d+$/.test(this.promptPriorityValue.toString())
      ) {
        return this.$vs.notify({
          title: this.lang.botMaker.addBot.priorities.invalidPriorityValue
            .title[this.languageSelected],
          text: this.lang.botMaker.addBot.priorities.invalidPriorityValue.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      }
      if (
        this.promptPriorityIntents.length === 0 &&
        this.promptPriorityTags.length === 0
      ) {
        return this.$vs.notify({
          title: this.lang.botMaker.addBot.priorities.invalidPriorityValue
            .title[this.languageSelected],
          text: this.lang.botMaker.addBot.priorities.invalidPriorityValue.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      }
      if (
        this.editingPriority &&
        this.promptPriorityId &&
        this.promptPriorityIndex > -1
      ) {
        taggedPrioritiesService
          .updateTaggedPriority(this.promptPriorityId, {
            priority: parseInt(this.promptPriorityValue),
            intents: this.promptPriorityIntents,
            tags: this.promptPriorityTags
          })
          .then(response => {
            this.taggedPriorities[this.promptPriorityIndex].priority =
              response.data.priority
            this.taggedPriorities[this.promptPriorityIndex].intents =
              response.data.intents
            this.taggedPriorities[this.promptPriorityIndex].tags =
              response.data.tags
            this.taggedPriorities[this.promptPriorityIndex]._updatedAt =
              response.data._updatedAt
            this.$vs.notify({
              title: this.lang.botMaker.addBot.priorities.genericSuccess.title[
                this.languageSelected
              ],
              text: this.lang.botMaker.addBot.priorities.genericSuccess.text[
                this.languageSelected
              ],
              color: 'success'
            })
          })
          .catch(error => {
            console.error(error)
            this.$vs.notify({
              title: this.lang.botMaker.addBot.priorities.genericError.title[
                this.languageSelected
              ],
              text: this.lang.botMaker.addBot.priorities.genericError.text[
                this.languageSelected
              ],
              color: 'danger'
            })
          })
      } else {
        taggedPrioritiesService
          .createTaggedPriority({
            service: this.bot.service.id,
            priority: parseInt(this.promptPriorityValue),
            intents: this.promptPriorityIntents,
            tags: this.promptPriorityTags
          })
          .then(response => {
            this.taggedPriorities = [response.data].concat(
              this.taggedPriorities
            )
            this.$vs.notify({
              title: this.lang.botMaker.addBot.priorities.genericSuccess.title[
                this.languageSelected
              ],
              text: this.lang.botMaker.addBot.priorities.genericSuccess.text[
                this.languageSelected
              ],
              color: 'success'
            })
          })
          .catch(error => {
            console.error(error)
            this.$vs.notify({
              title: this.lang.botMaker.addBot.priorities.genericError.title[
                this.languageSelected
              ],
              text: this.lang.botMaker.addBot.priorities.genericError.text[
                this.languageSelected
              ],
              color: 'danger'
            })
          })
      }
    },
    addToArray(array, value) {
      if (
        this[value].trim().length > 0 &&
        this[array].indexOf(this[value].trim()) === -1
      ) {
        this[array].push(this[value].trim())
        this[value] = ''
      }
    },
    isValid(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode

      if (charCode >= 48 && charCode <= 57) {
        if (parseInt(`${this.promptPriorityValue}${evt.key}`) > 1000) {
          console.log(
            `${this.promptPriorityValue}${evt.key}`,
            parseInt(`${this.promptPriorityValue}${charCode}`),
            evt.key
          )
          evt.preventDefault()
        }
        return true
      } else {
        evt.preventDefault()
      }
    },
    toggleAddPriorityNew() {
      this.isPromptActive = !this.isPromptActive
      if (this.isPromptActive) {
        this.editingPriority = false
        this.promptPriorityId = null
        this.promptPriorityIndex = -1
        this.promptPriorityValue = 100
        this.promptPriorityIntentValue = ''
        this.promptPriorityIntents = []
        this.promptPriorityTagValue = ''
        this.promptPriorityTags = []
      }
    },
    toggleAddPriorityEdit(tr, indextr) {
      this.isPromptActive = !this.isPromptActive
      if (this.isPromptActive) {
        this.editingPriority = true
        this.promptPriorityId = tr._id
        this.promptPriorityIndex = indextr
        this.promptPriorityValue = tr.priority
        this.promptPriorityIntentValue = ''
        this.promptPriorityIntents = [...tr.intents]
        this.promptPriorityTagValue = ''
        this.promptPriorityTags = [...tr.tags]
      }
    }
  },
  async mounted() {
    if (this.isEditing && this.bot && this.bot.service) {
      try {
        const { data } = await taggedPrioritiesService.getTaggedPriorities({
          serviceId: this.bot.service.id
        })
        this.taggedPriorities = data
      } catch (ex) {
        console.error(ex)
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.derive-cell {
  display: -webkit-inline-box;
  span {
    display: inherit;
    .con-vs-checkbox {
      align-items: unset;
    }
    .multiselect {
      width: 230px;
      &--disabled {
        .multiselect__select {
          height: 42px;
        }
      }
      .multiselect__content-wrapper {
        font-size: 1rem;
        max-height: 80px !important;
      }
      .multiselect__input {
        font-size: 1rem;
      }
    }
  }
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
}
.with-space {
  margin-right: 5px;
}
</style>

<style lang="scss">
.sizeSelectsSettings {
  .form-group-label {
    align-items: center;
    display: flex;
  }
  &.allSizes {
    justify-content: space-between;
    max-width: 350px;
    margin: auto;
    .con-select.selectSize {
      width: 45%;
    }
  }
}

.w-100 {
  width: 100%;
}

.w-60 {
  width: 60%;
}
</style>
